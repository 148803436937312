import React from "react"
import { Helmet } from "react-helmet"
// import favicon from "~image/favicon.png"
// import Header from "../Header"
import Meta from "../Meta"
import { useStaticQuery, graphql } from "gatsby"

export default function PageSEO({ meta, children }) {
  const data = useStaticQuery(graphql`
    query IndexLayoutQuery {
      settingsYaml {
        siteTitle
        siteDescription
        # googleTrackingId
        # socialMediaCard {
        #   image
        # }
      }
    }
  `)

  const {
    siteTitle,
    //  socialMediaCard,
    //  googleTrackingId
  } = data.settingsYaml || {}

  return (
    <>
      <Helmet
        defaultTitle={siteTitle}
        titleTemplate={`%s | ${siteTitle}`}
        htmlAttributes={{
          lang: "en",
        }}
      >
        {meta?.title}
        {meta?.structuredData && (
          <script type="application/ld+json">{meta.structuredData}</script>
        )}
      </Helmet>
      <Meta
        // googleTrackingId={googleTrackingId}
        // absoluteImageUrl={
        //   socialMediaCard && socialMediaCard.image && socialMediaCard.image
        // }
        {...meta}
        {...data.settingsYaml}
      />

      {children}
    </>
  )
}
