// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
  // {
  //   name: "shop",
  //   label: "Products",
  // },
  {
    name: "portfolio",
    label: "Portfolio",
  },
  {
    name: "megamenu",
    label: "Services",
    megamenu: true,
    col: 12,
    items: {
      //  image:{
      //   url:menuImage,
      //   col:"5",
      //  },
      col_1: {
        title: "DEVELOPMENT",
        titleLink: "service/development",
        items: [
          {
            name: "service/development/website",
            label: "Website Development",
          },
          {
            name: "service/development/web-app",
            label: "Web App Development",
          },
          {
            name: "service/development/app",
            label: "Mobile App Development",
          },
        ],
      },
      col_2: {
        title: "DESIGN",
        titleLink: "service/design",
        items: [
          {
            name: "service/design/brand",
            label: "Brand Design",
          },
          {
            name: "service/design/ui-ux",
            label: "UI / UX Design",
          },
          {
            name: "service/design/print",
            label: "Print Design",
          },
        ],
      },
      col_3: {
        title: "MARKETING",
        titleLink: "service/marketing",
        items: [
          {
            name: "service/marketing/social-media",
            label: "Social Media Marketing",
          },
          {
            name: "service/marketing/seo",
            label: "Search Engine Optimization (SEO)",
          },
          {
            name: "service/marketing/content",
            label: "Content Marketing",
          },
        ],
      },
    },
  },

  {
    name: "about",
    label: "About",
    items: [
      { name: "about-us", label: "About Us" },
      {
        name: "blog",
        label: "Blog",
        items: [
          { name: "blog", label: "All" },
          { name: "blog/design", label: "Design" },
          { name: "blog/development", label: "Development" },
          { name: "blog/content", label: "Content" },
        ],
      },
      {
        name: "careers",
        label: "Careers",
      },
    ],
  },

  // {
  //   name: "contact",
  //   label: "Contact",
  // },
]
export default menuItems
