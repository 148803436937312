import React from "react"
import GlobalHeaderContext, {
  headerDefault,
} from "../../../context/GlobalHeaderContext"
import PageSEO from "./PageSEO"
import HeaderButton from "../Header/InnerPageHeader"
import { Link } from "gatsby"

const innerPageDefault = {
  headerClasses:
    "light-header site-header site-header--menu-end site-header--button-sep position-relative",
  containerFluid: false,
  darkLogo: true,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnText="Contact"
      btnLink="/contact"
      as={Link}
      mr="15px"
      mrLG="0"
    />
  ),
}

const PageWrapper = ({
  children,
  headerConfig = null,
  innerPage = false,
  innerPageFooter = false,
  meta = {},
}) => {
  const activeHeader = innerPage ? innerPageDefault : headerDefault
  const sitectx = React.useContext(GlobalHeaderContext)

  React.useEffect(() => {
    sitectx.changeHeader({ ...activeHeader, ...headerConfig })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {/* <Nav /> */}
      <PageSEO meta={meta} />
      {children}
    </>
  )
}

export default PageWrapper
